<template>
  <div>
    <b-row class="map-widget-group">
      <b-col>
        <Group groupName="GroupGlobalMap" />
      </b-col>
    </b-row>
    <b-row class="global-chart-group">
      <b-col>
        <Group groupName="GroupGeneralCharts" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";
import Group from "../components/groups/Group.vue";

export default {
  components: {
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    draggable,
    Group,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.map-widget-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
.global-table-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
.global-chart-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
.outer-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
.global-map {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2.6px !important;
}
</style>
