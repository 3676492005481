<template>
  <div>
    <l-map
      ref="leafletMap"
      style="z-index: 0; max-height: 470px; font-size: 50px"
      :zoom="zoom"
      :center="center"
      :minZoom="zoom"
      :maxZoom="zoom"
    >
      <l-tile-layer
        :url="mapTiler"
        style="font-size: 50px !important"
        :continuousWorld="false"
        :noWrap="true"
      />
      <div v-for="country in data.countryList" :key="country.name">
        <l-circle
          v-if="country.name == 'Turkey'"
          :lat-lng="circle.center"
          :radius="250000"
          :color="circle.color"
          @click="navigateTo(country)"
        />
        <l-marker
          v-if="country.name == 'Turkey'"
          :lat-lng="circle.center"
          :icon="createLabelIcon(country.name)"
          @click="navigateTo(country)"
        />

        <l-circle
          v-if="country.name == 'Brazil'"
          :lat-lng="[-23.093809, -47.204463]"
          :radius="250000"
          :color="'yellow'"
          @click="navigateTo(country)"
        />
        <l-marker
          v-if="country.name == 'Brazil'"
          :lat-lng="[-23.093809, -47.204463]"
          :icon="createLabelIcon(country.name)"
          @click="navigateTo(country)"
        />

        <l-circle
          v-if="country.name == 'China'"
          :lat-lng="[38.923713, 121.660881]"
          :radius="250000"
          :color="circle.color"
          @click="navigateTo(country)"
        />
        <l-marker
          v-if="country.name == 'China'"
          :lat-lng="[38.923713, 121.660881]"
          :icon="createLabelIcon(country.name)"
          @click="navigateTo(country)"
        />

        <l-circle
          v-if="country.name == 'United States'"
          :lat-lng="[37, -80]"
          :radius="200000"
          :color="'red'"
          @click="navigateTo(country)"
        />
        <l-marker
          v-if="country.name == 'United States'"
          :lat-lng="[37, -80]"
          :icon="createLabelIcon(country.name)"
          @click="navigateTo(country)"
        />
      </div>
    </l-map>
  </div>
</template>

<script>
import L from "leaflet";
import { ref } from "@vue/composition-api";
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LPolygon,
  LGeoJson,
} from "vue2-leaflet";
import { Icon, DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import i18n from "@/libs/i18n";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
    LGeoJson,
  },
  props: {
    data: {
      widgetType: String,
      countryList: Array,
    },
  },
  methods: {
    navigateTo(country) {
      this.$router.push(`/country/${country.name}/id=${country.id}/tab=0`);
    },
    createLabelIcon(labelText) {
      return new DivIcon({
        className: "country-label",
        html: `<div class="label-text">${i18n.t(labelText)}</div>`,
      });
    },
  },
  setup() {
    const url = ref("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const mapTiler =
      "https://api.maptiler.com/maps/dataviz-light/{z}/{x}/{y}.png?key=3jhKmrz8wswwFDjZ8O3h";
    const leafletMap = ref();
    const noWrap = true;
    const zoom = ref(2.9);
    const center = [13, 20];
    const markerLatLng = [38.3325354, 27.1198419];
    const circle = {
      center: [38.3325354, 27.1198419],
      radius: 100000,
      color: "green",
    };
    const polygon = {
      latlngs: [
        [2, 2],
        [2, 4],
        [6, 5],
        [6, 5],
      ],
      color: "#ff0000",
    };
    return {
      url,
      noWrap,
      zoom,
      center,
      markerLatLng,
      circle,
      polygon,
      leafletMap,
      mapTiler,
    };
  },
};
</script>

<style lang="scss">
.leaflet-control-attribution {
  display: none;
}
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
  }
}
.country-label {
  .label-text {
    background: rgba(255, 255, 255, 0);
    width: 200px;
    padding-left: 24px;
    border-radius: 3px;
    color: rgb(104, 104, 104);
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
