<template>
  <div>
    <b-card class="map-widget-card" style="padding-top: 5px" no-body>
      <b-row
        style="margin-bottom: -10px; margin-left: 10px"
        v-for="legend in legends"
        :key="legend.text"
      >
        <b-col cols="2" style="margin-left: -10px">
          <svg width="10" height="10">
            <rect
              width="10"
              height="10"
              :style="`fill:${legend.color};`"
            ></rect>
          </svg>
        </b-col>
        <b-col cols="10">
          <p style="font-size: x-small; margin-left: -15px">
            {{ $t(legend.text) }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { ref, reactive } from "@vue/composition-api";
import { BRow, BCol, BCard } from "bootstrap-vue";
import { setgroups } from "process";
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    data: null,
  },
  setup(props) {
    const legends = reactive([
      { color: "red", text: "<50% Efficiency" },
      { color: "gold", text: "50% to 80% Efficiency" },
      { color: "green", text: "> 80% Efficiency" },
      { color: "purple", text: "Disabled" },
      { color: "blue", text: "No Final Production" },
    ]);
    return { legends };
  },
};
</script>
<style scoped>
.turquoise {
  color: #01a9ac;
}
.pink {
  color: #fe8a7d;
}
.green {
  color: #0ac282;
}
.orange {
  color: #fe9365;
}
.blue {
  color: #045fe9;
}
.yellow {
  color: #edd000;
}
.map-widget-card {
  z-index: 100;
  width: auto;
  position: absolute;
  top: -140px;
  left: 30px;
}
</style>
